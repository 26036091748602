@import '~assets/colors/colors.module.scss';
@import '~assets/breakpoints/breakpoints.module.scss';

.sidebarWrapper {
  position: sticky;
  top: 0;
  left: 0;
  bottom: 0;
  height: 100vh;
  z-index: 2;
  max-width: 185px;
  padding: 1rem;
  background-color: map-get($map: $colors, $key: 'base-color');
  -webkit-box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  -moz-box-shadow: 10px 0px 10px 0px rgba(0, 0, 0, 0.25);
  box-shadow: 4px 0px 10px 0px rgba(0, 0, 0, 0.25);
  transition: 0.3s ease-in all;

  @include xxlDesktop {
    max-width: 320px;
    padding: 3rem;
  }
  @include xlDesktop {
    max-width: 240px;
    padding: 2rem;
  }
  @include tablet {
    padding: 0.5rem;
  }
  @include smTablet {
    padding: 0.5rem;
  }
  @include xlPhone {
    position: fixed;
    padding: 0rem;
    transform: translate(-100%);
  }
}

.sidebarWrapper.collapsed {
  transform: translate(0%);
}
.sidebarWrapper:not(.collapsed) {
  box-shadow: none;
}

.sidebar,
ul {
  height: 100%;
}

.sidebar {
  padding: 0 1rem;
  position: relative;
}

.gridAction,
.logout {
  & > div {
    width: 120px;
    height: 60px;
  }
  margin: 1rem 0;
  @include xxlDesktop {
    margin: 3rem 0;
    & > div {
      width: 220px;
      height: 90px;
    }
  }
  @include xlDesktop {
    margin: 2rem 0;
    & > div {
      width: 160px;
      height: 80px;
    }
  }
  @include smDesktop {
    margin: 1.5rem 0;
  }
  @include xsmDesktop {
    margin: 1rem 0;
  }
  @include tablet {
    margin: 1rem 0;
  }
  @include smTablet {
    margin: 0.5rem 0;
  }
  @include xlPhone {
    margin: 1rem 0;
  }
  @include lgPhone {
    margin: 1rem 0;
  }
  @include phone {
    margin: 0.5rem 0;
  }

  @media screen and (min-width: 700px) and (max-height: 580px) {
    margin: 0.75rem 0;
  }
  @media screen and (min-width: 812px) and (max-height: 375px) {
    margin: 0.5rem 0;
  }
  @media screen and (max-width: 736px) and (max-height: 420px) {
    margin: 0.5rem 0;
  }
  @media screen and (max-width: 667px) and (max-height: 375px) {
    margin: 0.5rem 0;
  }
  @media screen and (max-width: 568px) and (max-height: 320px) {
    margin: 0.25rem 0;
  }
  // background-color: #fff;
}

.logout {
  margin-top: 2rem;
  @media screen and (max-width: 736px) and (max-height: 420px) {
    margin-top: 0rem;
  }
  @media screen and (min-width: 812px) and (max-height: 375px) {
    margin-top: 0rem;
  }
  @media screen and (max-width: 667px) and (max-height: 375px) {
    margin-top: 0rem;
  }
  @media screen and (max-width: 568px) and (max-height: 320px) {
    margin-top: 0rem;
  }
}

.logo {
  font-family: 'Conthrax-sb';
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
  font-size: 1.4rem;
  span {
    color: map-get($colors, 'white');
    span {
      color: map-get($colors, 'accent-color');
    }
    @include xxlDesktop {
      font-size: 2.4rem;
    }
    @include xlDesktop {
      font-size: 1.6rem;
    }
    @include xsmDesktop {
      font-size: 1.2rem;
    }
    @include tablet {
      font-size: 1rem;
    }
    @include smTablet {
      font-size: 0.8rem;
    }
    @include xlPhone {
      font-size: 0.7rem;
    }
    @media screen and (max-width: 768px) and (max-height: 580px) {
      font-size: 0.6rem;
    }
    @media screen and (min-width: 812px) and (max-height: 375px) {
      font-size: 0.6rem;
    }
    @media screen and (min-width: 568px) and (max-height: 320px) {
      font-size: 0.6rem;
    }
  }
}

ul {
  list-style: none;
  padding: 0 !important;
  @include flex(center, space-around, column);
}

.logo,
.menu,
.gridItem,
.version,
.sidebar {
  display: flex;
  align-items: center;
  justify-content: center;
}

.version {
  color: white;
  user-select: none;
  -ms-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  pointer-events: none;
  @include xxlDesktop {
    font-size: 1.8rem;
  }
  @include xlDesktop {
    font-size: 1rem;
  }
  @include xlPhone {
    font-size: 0.7rem;
  }
  @media screen and (max-width: 830px) and (max-height: 420px) {
    font-size: 0.6rem;
  }
}
.gridItem {
  flex-direction: column;
  justify-content: space-between;
}
