@import "src/assets/colors/colors.module.scss";
@import "~assets/breakpoints/breakpoints.module.scss";

.container a{
  @include flex(center, space-around, column);
  text-decoration: none;
  text-align: center;
  span{
    transition: .2s ease-in all;
  }
  svg path{
    transition: .2s ease-in all;
    fill: white;
  }
  &:hover:not(.active){
    text-decoration: none;
    background: #3E88A370;
    padding: 4px;
    border-radius: 15px;
    color: white !important;
    -webkit-box-shadow: 0px 0px 10px 4px #3e88a370;
    -moz-box-shadow: 0px 0px 10px 4px #3e88a370;
    box-shadow: 0px 0px 10px 4px #3e88a370;
    @include xxlDesktop{
      padding: 4px;
    }
    @include xlDesktop{
      padding: 4px;
    }
  }
  &:hover{
    text-decoration: none;
    padding: 4px;
    border-radius: 15px;
    color: white !important;
    @include xxlDesktop{
      padding: 4px;
    }
    @include xlDesktop{
      padding: 4px;
    }
  }
  .actionIcon:last-child{
    width: 1.7rem;
    height: 1.7rem;    
  }
  @include xxlDesktop{
    span{
      font-size: 2rem;
    }
    .actionIcon:last-child{
      width: 3rem;
      height: 3rem;
    }
  }
  @include xlDesktop{
    span{
      font-size: 1.4rem;
    }
    .actionIcon:last-child{
      width: 2.4rem;
      height: 2.4rem;
    }
  }
  @include smDesktop{
    span{
      font-size: .8rem;
    }
    .actionIcon:last-child{
      width: 1.4rem;
      height: 1.4rem;
    }
  }  
  @include xsmDesktop{
    span{
      font-size: 1rem;
    }
    .actionIcon:last-child{
      width: 1.5rem;
      height: 1.5rem;
    }
  }
  @include tablet{
    span{
      font-size: .9rem;
    }
    .actionIcon:last-child{
      width: 1.4rem;
      height: 1.4rem;
    }
  }
  @include smTablet{
    span{
      font-size: .7rem;
    }
    .actionIcon:last-child{
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  @include xlPhone{
    span{
      font-size: .6rem;
    }
    .actionIcon:last-child{
      width: 1rem;
      height: 1rem;
    }
  }
  @include lgPhone{
    span{
      font-size: .6rem;
    }
    .actionIcon:last-child{
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  @include phone{
    span{
      font-size: .6rem;
    }
    .actionIcon:last-child{
      width: 1rem;
      height: 1rem;
    }
  }
  @media screen and (max-width: 768px) and (max-height: 580px){
    span{
      font-size: .6rem;
    }
    .actionIcon:last-child{
      width: 1.2rem;
      height: 1.2rem;
    }
  }
  @media screen and (max-width: 736px) and (max-height: 420px){
    span{
      font-size: .6rem;
    }
    .actionIcon:last-child{
      width: 1rem;
      height: 1rem;
    }
  }
  @media screen and (min-width: 812px) and (max-height: 375px){
    span{
      font-size: .6rem;
    }
    .actionIcon:last-child{
      width: .9rem;
      height: .9rem;
    }
  }
  @media screen and (max-width: 667px) and (max-height: 375px){
    span{
      font-size: .6rem;
    }
    .actionIcon:last-child{
      width: .9rem;
      height: .9rem;
    }
  }
  @media screen and (max-width: 568px) and (max-height: 320px){
    span{
      font-size: .5rem;
    }
    .actionIcon:last-child{
      width: .7rem;
      height: .7rem;
    }
  }
}

.active{
  background: #3e88a3;
  padding: 4px;
  border-radius: 15px;
  color: white !important;
  -webkit-box-shadow: 0px 0px 10px 4px #3e88a340;
  -moz-box-shadow: 0px 0px 10px 4px #3e88a340;
  box-shadow: 0px 0px 10px 4px #3e88a340;
  @include xxlDesktop{
    padding: 4px;
  }
  @include xlDesktop{
    padding: 4px;
  }
}

.notActive {
  padding: 4px;
  border-radius: 15px;
  @include xxlDesktop{
    padding: 4px;
  }
  @include xlDesktop{
    padding: 4px;
  }
}

.displayActionText{
  color: white;
}