
.alert {
  z-index: 9999;
  position: fixed !important;
  top: 10px;
  right: 10%;
  margin: 0 auto;
  width: 350px;
  transform: translateX(37%);
  padding: 10px 10px;
  border: 0;
  font-size: 16px;

  .alert-inner--icon {
    font-size: 1.25rem;
    margin-right: 1.25rem;
    display: inline-block;
    vertical-align: middle;

    i.ni {
      position: relative;
      top: 1px;
    }
  }
  .alert-inner--text {
    display: inline-block;
    vertical-align: middle;
  }
}

.alert-success {
  background-color: #3eba5a !important;
  border-color: #3eba5a !important;
}
.alert-success hr {
  border-top-color: #3eba5a;
}
.alert-success .alert-link {
  color: #3eba5a;
}

.alert-danger {
  background-color: #bd2130 !important;
  border-color: #bd2130 !important;
}
.alert-danger hr {
  border-top-color: #bd2130;
}
.alert-danger .alert-link {
  color: #bd2130;
}
.alert:not(.alert-secondary) {
  color: white;
}

[class*='alert-'] {
  .alert-link {
    color: white;
    border-bottom: 1px dotted rgba(white, 0.5);
  }
}

.alert-heading {
  font-weight: 800;
  font-size: 14px;
  margin-top: 0.15rem;
}

.alert-dismissible {
  .close {
    top: 50%;
    right: 10px;
    transform: translateY(-50%);
    color: rgba(white, 0.6);
    opacity: 1;

    &:hover,
    &:focus {
      color: rgba(white, 0.9);
      opacity: 1 !important;
    }

    & > span:not(.sr-only) {
      font-size: 1.5rem;
      background-color: transparent;
      color: rgba(white, 0.6);
    }

    &:hover,
    &:focus {
      & > span:not(.sr-only) {
        background-color: transparent;
        color: rgba(white, 0.9);
      }
    }
  }
}
.close {
  padding: 0.6rem 1.25rem !important;
}
