.pagination {
    justify-content: flex-end !important;
}

.page-item.active .page-link {
    background-color: #BA2D0B !important;
    border-radius: 50% !important;
    color: white !important;
}

.page-link {
    padding: .3rem .6rem !important;
    color: #4a4a4a !important;
    border: none !important;
    border-radius: 50% !important;
    margin-left: 5px !important;
}