$spinner: #73b2ca;
$background: #3a3a3a;
$size: 40px;

.spinner {
  margin-top: 2rem;
  width: $size;
  height: $size;
  animation: spinner 1s linear infinite;

  span {
    position: absolute;
    width: 100%;
    height: 100%;
    border-radius: 50%;
  }

  .spinnerInner1 {
    background: linear-gradient(
      to right,
      rgba($spinner, 0) 0%,
      rgba($spinner, 0) 50%,
      $spinner 51%
    );
  }

  .spinnerInner2 {
    background: linear-gradient(to top, rgba($spinner, 0) 0%, $spinner 100%);
  }

  .spinnerInner3 {
    $offset: $size * 0.1;
    top: $offset;
    left: $offset;
    width: $size - ($offset * 2);
    height: $size - ($offset * 2);
    background: white;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
