@import "src/assets/colors/colors.module.scss";

.collapsible {
  //background-color: cadetblue;
  border: 0.3rem;
  cursor: pointer;
  border-radius: 5px;
  color: #FFFFFF;
  margin-bottom: 1rem;
&.active {
      background: #3E88A3;
      padding: 5px;
      border-radius: 5px;
      color: white !important;
      -webkit-box-shadow: 0px 0px 10px 4px #3e88a340;
      -moz-box-shadow: 0px 0px 10px 4px #3e88a340;
      box-shadow: 0px 0px 10px 4px #3e88a340;
  }
.content {
  transition: height 1.5s;
}

}

.meeting_members {
  display: inline-block;
  padding: 10px;
  background-color: #f3f3f3;
  border: 2px;
  text-align: center;
}

.row {
  display: flex;
  justify-content: space-between;
  width: 100%;
  background-color: #3A3A3A;
  padding-top: 5px;
  padding-bottom: 10px;
  &:hover:not(.active) {
    text-decoration: none;
    background: #3E88A370;
    padding: 5px;
    border-radius: 5px;
    color: white !important;
    -webkit-box-shadow: 0px 0px 10px 4px #3e88a370;
    -moz-box-shadow: 0px 0px 10px 4px #3e88a370;
    box-shadow: 0px 0px 10px 4px #3e88a370;
  }
  &.active{
      background: #3E88A3;
      padding: 5px;
      border-radius: 5px;
      color: white !important;
      -webkit-box-shadow: 0px 0px 10px 4px #3e88a340;
      -moz-box-shadow: 0px 0px 10px 4px #3e88a340;
      box-shadow: 0px 0px 10px 4px #3e88a340;
  }
}

.arrowIcon {
  //height: 14px;
  //width: 14px;
  padding: 4px;
  border-radius: 50%;
  background-color: map-get($colors, "base-color");
  transition: 0.2s ease-in all;
  &:hover {
    cursor: pointer;
    background-color: map-get($colors, "base-color-hover");
  }
}

.arrowActive {
  transform: rotate(180deg) !important;
}

.meetingAddButton {
  background-color: map-get($colors, "base-color");
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--white);
  border: none;
  border-radius: 7px;
  padding: 8px 12px;
  transition: .2s ease-in-out all;
  margin-left: 8px !important;
  margin-right: 8px !important;
  &:hover:not(.active) {
    text-decoration: none;
    background: #3E88A370;
    //padding: 5px;
    border-radius: 5px;
    color: white !important;
    -webkit-box-shadow: 0px 0px 10px 4px #3e88a370;
    -moz-box-shadow: 0px 0px 10px 4px #3e88a370;
    box-shadow: 0px 0px 10px 4px #3e88a370;
  }
  &.active {
    background: #3E88A3;
    //padding: 5px;
    border-radius: 5px;
    color: white !important;
    -webkit-box-shadow: 0px 0px 10px 4px #3e88a340;
    -moz-box-shadow: 0px 0px 10px 4px #3e88a340;
    box-shadow: 0px 0px 10px 4px #3e88a340;
  }
}

.formButton {
  margin-bottom: 0.5rem;
}

.iconHover{
  transition: 0.15s ease-in all;
}

.iconHover:hover {
  transform: scale(1.15);
  cursor: pointer;
}