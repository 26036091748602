@import 'src/assets/colors/colors.module.scss';
@import 'src/assets/breakpoints/breakpoints.module.scss';

.container {
  background-color: map-get($map: $colors, $key: 'white-smoked');
  border: 1px solid #dddddd;
  border-radius: 8px;
  padding: 1.4rem;
  margin-bottom: 2rem;
  @include flex(flex-start, flex-start, column);
}

.header {
  @include flex(center, space-between);
  width: 100%;
  .title {
    font-size: 1rem;
    font-weight: 500;
    color: map-get($map: $colors, $key: 'dark-gray');
  }
}

.newVacationContainer {
  background-color: map-get($map: $colors, $key: 'white-smoked');
  border: 1px solid #73b2ca;
  border-radius: 8px;
  padding: 1.4rem;
  margin-bottom: 2rem;
  @include flex(flex-start, flex-start, column);
}

.vacationDays {
  font-weight: 500;
  font-size: 1.2rem;
}

.vacationButton {
  background-color: map-get($colors, 'base-color');
  font-size: 14px;
  font-weight: 400;
  text-align: center;
  color: var(--white);
  border: none;
  border-radius: 7px;
  padding: 8px 12px;
  transition: 0.2s ease-in-out all;
  margin-left: 8px !important;
  margin-right: 8px !important;
  margin-bottom: 8px;
  &:hover:not(.active) {
    text-decoration: none;
    background: lighten(map-get($colors, 'base-color'), 20%);
    //padding: 5px;
    border-radius: 5px;
    color: white !important;
    -webkit-box-shadow: 0px 0px 10px 4px #3e88a370;
    -moz-box-shadow: 0px 0px 10px 4px #3e88a370;
    box-shadow: 0px 0px 10px 4px #3e88a370;
  }
  &.active {
    background: #3e88a3;
    //padding: 5px;
    border-radius: 5px;
    color: white !important;
    -webkit-box-shadow: 0px 0px 10px 4px #3e88a340;
    -moz-box-shadow: 0px 0px 10px 4px #3e88a340;
    box-shadow: 0px 0px 10px 4px #3e88a340;
  }
}
.divider {
  width: 100%;
  height: 2px;
  background-color: rgb(230, 230, 230);
  margin: 8px 0;
}
