@import "~assets/breakpoints/breakpoints.module.scss";

.container404{
    @include flex(center, center, column);
    background-color: #1f2f35;
    overflow: hidden;
    min-height: 100vh;
    position: relative;
}


.message404{
    font-size: 4rem;
    font-weight: 600;
    margin-bottom: 1rem;
    color: white;
}

.img404{
    margin-top: 2rem;
    margin-bottom: 2rem;
    width: 20%;
}

.returnMessage404{
    font-size: 1.6rem;
    font-weight: 600;
    color: white;
    margin-top: 10rem;
    text-decoration: none;
    &:hover{    
        color: white;
    }
}

.err, .fof{
    font-size: 18rem;
    font-weight: 900;
    position: absolute;
}

.err{
    left: 0;
    top: 50%;
    transform: rotate(-90deg) translate(40%, -30%);
}
.fof{
    right: 0;
    top: 50%;
    transform: rotate(90deg) translate(-40%, -30%);
}