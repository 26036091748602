
@import "src/assets/colors/colors.module.scss";
@import "src/assets/breakpoints/breakpoints.module.scss";

.tableHeaderRow{
    margin-bottom: 1em;
    border-bottom: 1px solid #dddddd;
    display: flex;
    justify-content: space-between;
    padding-bottom: .5rem;
}

.tableHeaderCol{
    @include flex(flex-start, space-between);
    // flex-grow: .3 !important;
    display: flex;
    
    @media 
    only screen and (max-width: 1000px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
        flex-grow: 1 !important;
        align-self: center;
    }
    
    h3{
        font-size: 28px;
        font-weight: 500 !important;
        color: map-get($colors, "dark-gray");
        white-space: nowrap;
    }
}

.searchWrapper{
    @include flex();
}


//search field styles
.searchInput{
    border: none;
    border-bottom: 1px solid map-get($colors, "light-gray");
    background-image: url("~assets/img/search-icon.svg");
    background-repeat: no-repeat;
    background-position: right;
    font-size: 14px;
    margin-right: 2rem;
}
.searchInput:focus{
    outline: none;
    border-bottom: 2px solid map-get($colors, "light-gray");
}

.h3Title{
    font-size: 28px;
    font-weight: 500 !important;
    color: map-get($colors, "dark-gray");
}

.tableHeaderRow{
    margin-bottom: 1rem !important;
    border-bottom: 1px solid #dddddd;
    display: flex;
}
