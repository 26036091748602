$phone: 320px;
$lgPhone: 420px;
$xlPhone: 580px;
$smTablet: 720px;
$tablet: 768px;
$xsmDesktop: 1024px;
$smDesktop: 1366px;
$desktop: 1920px;
$xlDesktop: 2560px;
$xxlDesktop: 3840px;

@mixin phone {
  @media screen and (max-width: #{$phone}) {
    @content;
  }
}
@mixin phoneLandscape {
  @media screen and (max-height: #{$phone}) {
    @content;
  }
}
@mixin lgPhone {
  @media screen and (max-width: #{$lgPhone}) {
    @content;
  }
}
@mixin lgPhoneLandscape {
  @media screen and (max-height: #{$lgPhone}) {
    @content;
  }
}
@mixin xlPhone {
  @media screen and (max-width: #{$xlPhone}) {
    @content;
  }
}
@mixin smTablet {
  @media screen and (max-width: #{$smTablet}) {
    @content;
  }
}
@mixin tablet {
  @media screen and (max-width: #{$tablet}) {
    @content;
  }
}
@mixin xsmDesktop {
  @media screen and (max-width: #{$xsmDesktop}) {
    @content;
  }
}
@mixin smDesktop {
  @media screen and (max-width: #{$smDesktop}) {
    @content;
  }
}
@mixin desktop {
  @media screen and (max-width: #{$desktop}) {
    @content;
  }
}
@mixin xlDesktop {
  @media screen and (min-width: 1921px) and (max-width: #{$xlDesktop}) {
    @content;
  }
}
@mixin xxlDesktop {
  @media screen and (min-width: 2561px) and (max-width: #{$xxlDesktop}) {
    @content;
  }
}


@mixin flex ($align: center, $justify: center, $dir: row) {
  display: flex;
  align-items: $align;
  justify-content: $justify;
  flex-direction: $dir;
}