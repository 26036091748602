@import '~assets/colors/colors.module.scss';
@import '~assets/breakpoints/breakpoints.module.scss';

@font-face {
  src: url('~assets/fonts/conthrax-sb.ttf');
  font-family: 'Conthrax-sb';
}

.landing {
  background: map-get($colors, 'accent-color');
}

.container {
  @include flex(center, center);
  height: 100dvh;
  position: relative;
  overflow: hidden;
  background-image: url('~assets/img/background-landing.svg');
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-size: cover;
  background-position: bottom right;
  @include xsmDesktop {
    background-image: url('~assets/img/background-landing-rotated.svg');
    background-position: bottom left;
  }
  @include tablet {
    background-image: url('~assets/img/background-landing-rotated.svg');
    background-position: bottom left;
  }
  @include xlPhone {
    background-image: url('~assets/img/background-landing-rotated.svg');
    background-position: bottom left;
  }
  @include lgPhone {
    background-image: url('~assets/img/background-landing-rotated.svg');
    background-position: bottom left;
  }
  @include phone {
    background-image: url('~assets/img/background-landing-rotated.svg');
    background-position: bottom left;
  }
  @include phoneLandscape {
    background-image: url('~assets/img/background-landing.svg');
    background-position: bottom right;
  }
  @include lgPhoneLandscape {
    background-image: url('~assets/img/background-landing.svg');
    background-position: bottom right;
  }
}

.containerInner {
  max-width: 350px;
  margin: 0 auto;
  @include flex(center, center, column);
  z-index: 2;
  .logo {
    span {
      font-family: 'Conthrax-sb';
      font-size: 72px;
      color: map-get($colors, 'white');
      width: 100%;
      user-select: none;
      -ms-user-select: none;
      -moz-user-select: none;
      -webkit-user-select: none;
      pointer-events: none;
      span {
        color: map-get($colors, 'accent-color');
      }
    }
  }

  .signInButton {
    background: map-get($colors, 'white');
    color: map-get($colors, 'base-color');
    font-weight: 600;
    font-size: 16px;
    padding: 0.4rem 1rem;
    border-radius: 4px;
    border: none;
    outline: none;
    cursor: pointer;
    margin-top: 1rem;
    transition: 0.2s ease-in all;
    span {
      text-decoration: none;
    }
    &:hover {
      background: rgb(230, 230, 230);
    }
  }

  //media queries for all screen sizes
  @include xxlDesktop {
    .logo span {
      font-size: 138px;
    }
    .signInButton span {
      font-size: 2.1rem;
      padding: 0.6rem 1.4rem;
    }
  }
  @include xlDesktop {
    .logo span {
      font-size: 102px;
    }
    .signInButton span {
      font-size: 1.8rem;
      padding: 0.5rem 1.2rem;
    }
  }
  @include desktop {
    .signInButton span {
      font-size: 1.1rem;
    }
  }
  @include smDesktop {
    .logo span {
      font-size: 64px;
    }
    .signInButton span {
      font-size: 18px;
    }
  }
  @include xsmDesktop {
    .logo span {
      font-size: 76px;
    }
    .signInButton span {
      font-size: 16px;
    }
  }
  @include tablet {
    .logo span {
      font-size: 72px;
    }
    .signInButton span {
      font-size: 18px;
    }
  }
  @include xlPhone {
    .logo span {
      font-size: 64px;
    }
    .signInButton span {
      font-size: 16px;
    }
  }
  @include lgPhone {
    .logo span {
      font-size: 58px;
    }
    .signInButton span {
      font-size: 15px;
    }
  }
  @include phone {
    .logo span {
      font-size: 48px;
    }
    .signInButton span {
      font-size: 14px;
    }
  }
}
