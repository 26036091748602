:root {
  --white: #ffffff;
  --dark-blue: #598ca6;
  --dark-blue-active: #3c6c85;
  --cancel-button-gray: #595959;
  --cancel-button-gray-active: #413d3d;
  --coral: #e87d7e;
  --coral-active: #d85c5e;
  --modal-title: #4a4a4a;
  --modal-input-border: #979797;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif, AvenirNext;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #f3f3f3 !important;
}

html {
  background-color: #f3f3f3;
}

input {
  background-color: #f3f3f3;
}

.input-feedback {
  color: red;
  margin-top: 0.25rem;
  font-size: 13px;
  font-style: italic;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

thead {
  font-weight: bold;
  color: var(--white);
}

td {
  font-size: 12px;
  font-weight: 500;
  color: #4a4a4a;
}

@media only screen and (max-width: 1000px),
  (min-device-width: 768px) and (max-device-width: 1024px) {
  .table td {
    padding: 1.25rem 0.75rem 0.25rem 0.75rem !important;
  }
}

.contentWrap {
  margin-top: 4em;
}

.addButton {
  font-size: 13px;
  font-weight: 500;
  text-align: center;
  color: var(--white);
  background-color: #3e88a3;
  border: none;
  border-radius: 7px;
  padding: 8px 12px;
  display: flex;
  align-items: center;
  transition: 0.2s ease-in-out all;
}

.remove-underline {
  text-decoration: none;
}
.addButton:hover {
  transition: 0.2s ease-in-out all;
  background-color: #35778f;
}

.addButton:focus {
  outline: none;
}

.addButton:active {
  background-color: var(--dark-blue-active);
}

.addButton:disabled {
  background-color: grey;
}

.addButtonIcon {
  padding-right: 5px;
}

.cancelButton,
.modalAddButton,
.deleteButton {
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  color: var(--white);
  border: none;
  border-radius: 7px;
  padding: 8px 12px;
  transition: 0.2s ease-in-out all;
  /* margin-left: 8px !important;
  margin-right: 8px !important; */
  width: 100%;
}

.cancelButton {
  background-color: var(--cancel-button-gray);
}

.cancelButton:focus {
  outline: none;
}
.cancelButton:hover {
  background-color: var(--cancel-button-gray-active);
}

.cancelButton:active {
  background-color: var(--cancel-button-gray-active);
}

.deleteButton {
  background-color: var(--coral);
}

.deleteButton:focus {
  outline: none;
}

.deleteButton:hover {
  background-color: var(--coral-active);
}

.deleteButton:active {
  background-color: var(--coral-active);
}

.modalTitle {
  font-size: 20px;
  font-weight: bold;
  color: var(--modal-title);
  padding-bottom: 20px;
  padding-top: 10px;
}

.flexStart {
  display: flex;
  align-items: start;
}

.modalContent {
  border: none !important;
  border-radius: 20px !important;
}

.modalLabel {
  font-size: 14px;
  color: var(--modal-title);
  font-weight: bold;
}
.radioOption {
  padding-left: 5px;
  padding-right: 5px;
}

.modalInput {
  /* border: solid 1px var(--modal-input-border) !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), inset 0 2px 0 0 var(--white); */
  height: 38px !important;
}
.modalTextArea {
  /* border: solid 1px var(--modal-input-border) !important;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.2), inset 0 2px 0 0 var(--white); */
  min-height: 82px !important;
}

.modalAddButton {
  background-color: #3e88a3;
}

.modalAddButton:hover {
  background-color: #35778f;
}
.modalAddButton:focus {
  outline: none;
}

.modalAddButton:active {
  background-color: var(--dark-blue-active);
}
.modalAddButton:disabled,
.modalAddButton {
  background-color: lighten(#35778f, 20%) !important;
}

.modalRowCenter {
  display: flex;
  justify-content: center;
  align-items: center;
}

.modalButtonMargin {
  margin-top: 10px;
  /* margin-left: 5px;
  margin-right: 5px; */
}

.m-r-5 {
  margin-right: 5px;
}

.m-t-5 {
  margin-top: 5px;
}

.m-t-10 {
  margin-top: 10px;
}

h3 {
  font-size: 24px;
  font-weight: bold !important;
  color: var(--modal-title);
}

.tableHeaderRow {
  margin-bottom: 1em;
  display: flex;
}

.nav {
  margin-bottom: 1.5em !important;
}

.justifyFlexEnd {
  justify-content: flex-end;
}

.alert-success {
  background-color: #3eba5a !important;
  border-color: #3eba5a !important;
}
.alert-success hr {
  border-top-color: #3eba5a;
}
.alert-success .alert-link {
  color: #3eba5a;
}

.alert-danger {
  background-color: #bd2130 !important;
  border-color: #bd2130 !important;
}
.alert-danger hr {
  border-top-color: #bd2130;
}
.alert-danger .alert-link {
  color: #bd2130;
}
.alert:not(.alert-secondary) {
  color: white;
}

.float-right {
  float: right;
}

/* react-calendar-timeline overrideing styles to match absence reasons */
.timeline-item-VACATION {
  background-color: #799dec !important;
  border-color: #799dec !important;
  color: black !important;
}
.timeline-item-UNIVERSITY {
  background-color: #a373e6 !important;
  border-color: #a373e6 !important;
  color: black !important;

}
.timeline-item-SICK_LEAVE {
  background-color: #a6df6d !important;
  border-color: #a6df6d !important;
  color: black !important;
}
.timeline-item-TIMETRACK {
  background-color: #ec716f !important;
  border-color: #ec716f !important;
  color: black !important;
}
.timeline-item-MISSING_TIMETRACK {
  background-color: #ec716f !important;
  border-color: #ec716f !important;
  color: black !important;
}
.timeline-item-BOOKING_OFFICE {
  background-color: #70e0d3 !important;
  border-color: #70e0d3 !important;
  color: black !important;
}
.timeline-item-REMOTE {
  background-color: #ae89b8 !important;
  border-color: #ae89b8 !important;
  color: black !important;
}
.react-calendar-timeline .rct-header-root {
  background: #73b2ca !important;
}
.react-calendar-timeline .rct-outer {
  border: 1px solid rgb(226, 226, 226) !important;
}
.react-calendar-timeline .rct-dateHeader {
  border-left: 1px solid #bbb !important;
  border-bottom: none !important;
}

.btn-secondary:not(:disabled):not(.disabled).active {
  background-color: #72b1ca !important;
  box-shadow: 0 0 0 0.2rem rgb(114 177 202 / 50%);
}



.my-component {
  padding: 20px;
}

.my-component__row {
  margin-bottom: 20px;
}

.my-component__holidays-col,
.my-component__filter-col {
  margin-bottom: 20px;
}

.my-component__table-col {
  overflow-x: auto;
}

.my-component__table {
  width: 100%;
  border-radius: 6px;
  border-collapse: collapse;
}

.my-component__thead {
  background-color: #73b2ca;
}

.my-component__thead-row {
  border-bottom: 2px solid #ccc;
}

.my-component__thead-cell {
  padding: 10px;
  font-weight: bold;
  text-align: left;
  border-right: 1px solid #ccc;
}

.my-component__thead-cell:last-child {
  border-right: none;
}

.my-component__tbody {
  border-bottom: 1px solid #ccc;
}

.my-component__user-row {
  background-color: #f9f9f9;
}

.my-component__user-label {
  font-weight: bold;
  padding: 10px;
  text-align: left;
}

.my-component__user-data {
  padding: 10px;
}

.my-component__annual-leave-text,
.my-component__timetrack-text {
  color: #333;
}

.my-component__absence-entry {
  padding: 5px;
}

.my-component__absence-key {
  font-weight: bold;
}

.my-component__absence-date {
  margin-left: 5px;
  color: #fff;
  font-weight: 600;
  padding: 8px;
  border-radius: 4px;
  background-color: #413d3d;
}
