@import "~assets/colors/colors.module.scss";
@import "~assets/breakpoints/breakpoints.module.scss";

.Root {
    position: relative;
    height: 100%;
    display: flex;
}

.contentWrap{
    max-width: unset;
    position: relative;
}

.burgerWrapper{
    display: none;
    @media screen and (max-width: 760px) and (max-height: 1000px), screen and (max-height: 430px) and (max-width: 800px) {
        display: flex;
        flex-direction: column;
        position: fixed;
        top: 10px;
        right: 10px;
        overflow: hidden;
        background-color: map-get($map: $colors, $key: "base-color");
        padding: 13px 8px;
        border-radius: 50%;
        z-index: 3;
        -webkit-box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.2); 
        -moz-box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.2); 
        box-shadow: 5px 5px 8px 0px rgba(0,0,0,0.2); 
    }
    .burgerLine{
        width: 28px;
        height: 2px;
        background: white;
        z-index: 1;
        margin-bottom: 6px;
        border-radius: 1px;
        &:last-child{
          margin-bottom: 0;
        }
      }   
}