@import 'src/assets/colors/colors.module.scss';

.container {
  margin-bottom: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  justify-content: center;
  padding-left: 0 !important;
  padding-right: 0 !important;

  .arrowIcon {
    padding: 4px;
    border-radius: 50%;
    background-color: map-get($colors, 'base-color');
    transition: 0.2s ease-in all;
    &:hover {
      cursor: pointer;
      background-color: map-get($colors, 'base-color-hover');
    }
  }
  .active {
    transform: rotate(180deg) !important;
  }
}

.month,
.allMonths {
  width: 100% !important;
  padding: 6px 12px !important;
  text-align: center !important;
  color: white !important;
  border-radius: 4px !important;
  border: none !important;
  font-size: 14px !important;
  transition: 0.2s ease-in all !important;
  margin: 8px 0 !important;
  background-color: #344952 !important;
  &:hover {
    cursor: pointer !important;
    background-color: #73b1ca !important;
  }
  &.active {
    background-color: #73b1ca !important;
  }
}
