@import "src/assets/colors/colors.module.scss";
@import "src/assets/breakpoints/breakpoints.module.scss";


.container{
    background-color: map-get($map: $colors, $key: "white-smoked");
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 1.4rem;
    margin-bottom: 2rem;
    @include flex(flex-start, flex-start, column);
}

.header{
    @include flex(center, space-between);
    width: 100%;
    .title{
        font-size: 1rem;
        font-weight: 500;
        color: map-get($map: $colors, $key: "dark-gray")
    }
    .percentage{
        background-color: #9CD39C;
        padding: .05rem .5rem;
        color: #408140;
        font-weight: 500;
        border-radius: 8px;
    }
}

.completedHours{
    font-weight: 500;
    font-size: 1.4rem;
    margin-bottom: 1rem;
}

.workingHours{
    font-size: .8rem;
    font-weight: 400;
}