@import "src/assets/colors/colors.module.scss";

.deleteModuleText {
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    color: map-get($colors, "dark-gray");
    padding-bottom: 23px;
}

.colPadding { 
    padding: 0 5px !important;
}

.rowCenter{
    justify-content: center;
}
