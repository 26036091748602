@import "src/assets/colors/colors.module.scss";
@import "src/assets/breakpoints/breakpoints.module.scss";

.container{
    background-color: map-get($map: $colors, $key: "white-smoked");
    border: 1px solid #dddddd;
    border-radius: 8px;
    padding: 1.4rem;
    margin-bottom: 2rem;
    @include flex(flex-start, flex-start, column);
}

.header{
    @include flex(center, space-between);
    width: 100%;
    margin-bottom: .5rem;
    .title{
        font-size: 1rem;
        font-weight: 500;
        color: map-get($map: $colors, $key: "dark-gray")
    }
    .percentage{
        background-color: #d39c9c;
        padding: .05rem .5rem;
        color: #814040;
        font-weight: 500;
        border-radius: 8px;
    }
}

.dates{
    display: flex;
    flex-wrap: wrap;
}

.completedHoursTitle{
    font-weight: 500;
    font-size: 1rem;
    margin-bottom: .5rem;
    color: map-get($map: $colors, $key: "dark-gray")
}
.completedHours{
    font-weight: 400;
    font-size: .8rem;
    margin: .2rem;
}

.workingHours{
    margin-top: 1rem;
    font-size: .8rem;
    font-weight: 400;
}