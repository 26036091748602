@import "src/assets/colors/colors.module.scss";


.table {
    border-collapse: separate;
    border-spacing: 0;
    th {
        background: #3E88A3;
        border: none !important;
        font-weight: 500 !important;
    }
    td {
        border-right: 1px solid map-get($colors, "table-border-color");
        border-bottom: 1px solid map-get($colors, "table-border-color");
        vertical-align: middle !important;
    }
    tr {
        background: map-get($colors, "white-smoked");
    }
    td:first-child{
        border-left: 1px solid map-get($colors, "table-border-color");
    }
    tr:first-child th:first-child {
        border-top-left-radius: 16px;
    }
    tr:first-child th:last-child {
        border-top-right-radius: 16px;
    }
     tr:last-child td:first-child {
        border-bottom-left-radius: 16px;
    }  
    tr:last-child td:last-child {
        border-bottom-right-radius: 16px;
    }
    @media 
    only screen and (max-width: 1000px),
    (min-device-width: 768px) and (max-device-width: 1024px)  {
        display: block;
        /* Force table to not be like tables anymore */
        table, thead, tbody, th, td, tr { 
            display: block; 
        }
        
        /* Hide table headers (but not display: none;, for accessibility) */
        thead tr { 
            position: absolute;
            top: -9999px;
            left: -9999px;
            width: 100%;
        }
        
        tr {
             border: 1px solid #ccc;
             padding: 10px;
             vertical-align: bottom;
             border-radius: 8px;
             margin-bottom: 10px;
         }
        
        td { 
            /* Behave  like a "row" */
            border: none;
            border-bottom: 1px solid #eee;
            border-top: none !important;
            position: relative;
            padding-left: 50%; 
            width: 100%;
            font-size: 14px;
            font-weight: 500;
            &:first-child{
                border-left: none;
            }
        }
        
        td:before { 
            /* Now like a table header */
            position: absolute;
            /* Top/left values mimic padding */
            top: 0px;
            left: 6px;
            width: 45%; 
            white-space: nowrap;
            vertical-align: bottom;
            font-weight: 700;
            color: #31a1ce;
            font-size: 12px;
        }
        /*
        Label the data
        */
        td:nth-of-type(1):before { content: "Project name"; }
        td:nth-of-type(2):before { content: "Client"; }
        td:nth-of-type(3):before { content: "Project Manager"; }
        td:nth-of-type(4):before { content: "Spent hours"; }
        td:nth-of-type(5):before { content: "Chargable hours"; }
    }
}
.thead {
    font-size: 13px;
    border: none;
    span {
        margin-left: 5px;    
    }
}

.iconHover{
    transition: 0.15s ease-in all;
}

.iconHover:hover {
    transform: scale(1.15);
    cursor: pointer;

}

.iconRotate {
    transform: rotate(180deg);
}

.row {
    align-items: center;
}

.noDataText {
    font-size: 24px;
    font-weight: bold;
    color: map-get($colors, "dark-gray");
}

.noData {
    text-align: center; 
}
