.container{
    padding: 16px 28px;
    // border: 1px solid #9bcadd;
    background-color: #fefefe;
    color: #333;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    width: fit-content;
    border-radius: 8px;
    box-shadow: 4px 4px 12px 0px rgba(0, 0, 0, 0.08);
}

.box {
    width: 20px;
    height: 20px;
    border-radius: 4px;
}
.items{
    // margin-top: 4px;
    // margin-left: 12px;
    display: flex;
    gap: 32px;
}
.item{
    display: flex;
    align-items: center;
    gap: 6px;
    &Vacation {
        @extend .item;
        background-color: #799dec;
        border: 2px solid #5885e7;
    }
    &MissingTimetrack {
        @extend .item;
        background-color: #ec716f;
        border: 2px solid #e24d4b;
    }
    &SickLeave {
        @extend .item;
        background-color: #a6df6d;
        border: 2px solid #7fc13d;
    }
}